import React from 'react'
import styled from 'styled-components'
import Layout from '../../components/layout'
import Helmet from 'react-helmet'
import {
  Section,
  Row,
  Col,
  Container,
  Responsive,
} from '../../components/containers/Layout'
import {
  FinePrint,
  H1,
  H2,
  H3,
  Heading,
  Paragraph,
  Quote,
} from '../../components/base/Typography'
import {Link} from 'gatsby'
import Button, { ButtonLink } from '../../components/base/Button'
import MealCard from '../../components/meal/MealCard'
import bl1 from '../../media/brooke-lark-96398-unsplash.jpg'
import jp from '../../media/jennifer-pallian-306915-unsplash.jpg'
import j from '../../media/photo-1528992761365-34a53564c222.jpeg'
import breakfast from '../../media/alexander-mils-365917-unsplash.jpg'
import {List, ListItem} from '../../components/containers/List'
import {Card} from '../../components/base/Card'
import Tag from '../../components/Tag'
import Polaroid, {Photo} from '../../components/base/Polaroid'
import hotdog from '../../media/hotdogs.jpeg'
import Image from '../../components/base/Image'
import bitesnap from '../../media/bitesnap-cropped.png'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {
  faCircle,
  faSearch,
  faCheck,
  faChartLine,
  faPlug,
  faStar,
} from '@fortawesome/free-solid-svg-icons'
import Contact from '../../components/Contact'
import {BREAKFAST_RESPONSE} from '../../data/api-responses'
import {RecognitionExample} from '../food-recognition'
import {PieChart} from '../../components/charts/PieChart'
import ItemTag from '../../components/item/ItemTag'
import {Flipper, Flipped} from 'react-flip-toolkit'
// - see it in action (Bitesnap)

import {Feature} from '../../components/base/Feature'
import {StackedIcon} from '../../components/base/StackedIcon'


const Slider = styled.input.attrs({ type: 'range' })`
  &[type=range] {
    -webkit-appearance: none;
    width: 100%;
    margin: 1.5px 0;
  }
  &[type=range]:focus {
    outline: none;
  }
  &[type=range]::-webkit-slider-runnable-track {
    width: 100%;
    height: 13px;
    cursor: pointer;
    box-shadow: 0px 0px 0px rgba(115, 115, 115, 0.26), 0px 0px 0px rgba(128, 128, 128, 0.26);
    background: #565dff;
    border-radius: 25px;
    border: 0.2px solid #565dff;
  }
  &[type=range]::-webkit-slider-thumb {
    box-shadow: 1.6px 1.6px 2.7px rgba(0, 0, 49, 0.39), 0px 0px 1.6px rgba(0, 0, 75, 0.39);
    border: 0px solid #a3a3a2;
    height: 16px;
    width: 16px;
    border-radius: 26px;
    background: #ffffff;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -1.7px;
  }
  &[type=range]:focus::-webkit-slider-runnable-track {
    background: #565dff;
  }
  &[type=range]::-moz-range-track {
    width: 100%;
    height: 13px;
    cursor: pointer;
    box-shadow: 0px 0px 0px rgba(115, 115, 115, 0.26), 0px 0px 0px rgba(128, 128, 128, 0.26);
    background: #565dff;
    border-radius: 25px;
    border: 0.2px solid #565dff;
  }
  &[type=range]::-moz-range-thumb {
    box-shadow: 1.6px 1.6px 2.7px rgba(0, 0, 49, 0.39), 0px 0px 1.6px rgba(0, 0, 75, 0.39);
    border: 0px solid #a3a3a2;
    height: 16px;
    width: 16px;
    border-radius: 26px;
    background: #ffffff;
    cursor: pointer;
  }
  &[type=range]::-ms-track {
    width: 100%;
    height: 13px;
    cursor: pointer;
    background: transparent;
    border-color: transparent;
    color: transparent;
  }
  &[type=range]::-ms-fill-lower {
    background: #565dff;
    border: 0.2px solid #565dff;
    border-radius: 50px;
    box-shadow: 0px 0px 0px rgba(115, 115, 115, 0.26), 0px 0px 0px rgba(128, 128, 128, 0.26);
  }
  &[type=range]::-ms-fill-upper {
    background: #565dff;
    border: 0.2px solid #565dff;
    border-radius: 50px;
    box-shadow: 0px 0px 0px rgba(115, 115, 115, 0.26), 0px 0px 0px rgba(128, 128, 128, 0.26);
  }
  &[type=range]::-ms-thumb {
    box-shadow: 1.6px 1.6px 2.7px rgba(0, 0, 49, 0.39), 0px 0px 1.6px rgba(0, 0, 75, 0.39);
    border: 0px solid #a3a3a2;
    height: 16px;
    width: 16px;
    border-radius: 26px;
    background: #ffffff;
    cursor: pointer;
    height: 13px;
  }
  &[type=range]:focus::-ms-fill-lower {
    background: #565dff;
  }
  &[type=range]:focus::-ms-fill-upper {
    background: #565dff;
  }

`


const Testimonial = ({rating, message, name}) => (
  <div
    style={{
      padding: '10px 16px',
      border: '1px solid #EEE',
      borderRadius: 8,
      margin: 6,
    }}
  >
    <FontAwesomeIcon icon={faStar} color={'gold'}/>
    <FontAwesomeIcon icon={faStar} color={'gold'}/>
    <FontAwesomeIcon icon={faStar} color={'gold'}/>
    <FontAwesomeIcon icon={faStar} color={'gold'}/>
    <FontAwesomeIcon icon={faStar} color={'gold'}/>
    <Paragraph short style={{margin: 2, padding: 2}}>
      {message}
    </Paragraph>
  </div>
)


const FAQ = ({reverse}) => (
  <Section style={{backgroundColor: 'white'}}>
    <Container reverse={reverse} style={{alignItems: 'flex-start'}}>
      <Col>
        <Row>
          <H1>Frequently Asked Questions</H1>
        </Row>
        <Row>
          {/*<StackedIcon icon={faPlug} size={3} style={{marginBottom: 20}}/>*/}
          <Col>
            <H2>How do you determine active users?</H2>
            <Paragraph narrow>
              An active user is any user who submitted new data to the API.
            </Paragraph>
            <H2>Do you offer hackathon access?</H2>
            <Paragraph narrow>
              Yes
            </Paragraph>
          </Col>
          {/*<StackedIcon icon={faChartLine} size={3} style={{marginBottom: 20}}/>*/}
          <Col>
            <H2>Is caching allowed?</H2>
            <Paragraph narrow>
              For enterprise customers only
            </Paragraph>
          </Col>
        </Row>
      </Col>
    </Container>
  </Section>
)


class FeatureTable extends React.Component {

}

class PricingCard extends React.Component {
  static defaultProps = {
    title: '',
    subtitle: '',
    features: [],
    price: undefined,
    buttonText: 'Sign Up',
    lift: 1,
    flex: 1

  }
  render() {
    return (
      <Card raised style={{
        // flexGrow: 1,
        flex: this.props.flex || 1,
        // flexBasis: 0,
        padding: 30,
        flexDirection: 'column',
        display: 'flex',
        boxShadow: `${this.props.lift * .2 + 1}px 6px 20px -2px rgba(0,0,0,${this.props.lift * .1})`,
      }}>
        <div style={{flexGrow: 1}}>
          <H2>{this.props.title}</H2>
          <Paragraph>{this.props.subtitle}</Paragraph>
          {
            this.props.features.map(f =>
              <Feature key={f}>{f}</Feature>
            )
          }

          {this.props.children}
        </div>
        <div>
          {this.props.price && (
            typeof this.props.price === 'number' ?
              <Heading>
                ${this.props.price} <span style={{fontSize: '1.4rem'}}>/ month</span>
              </Heading>
              : this.props.price)
          }
          <Row style={{padding: 0}}>
            <ButtonLink
              href={this.props.url}
              size={'large'} type={'submit'} style={{flex: 1, margin: '10px 0'}}>
              {this.props.buttonText}
            </ButtonLink>
          </Row>
        </div>
      </Card>
    )
  }
}


class PriceCalculator extends React.Component {
  static defaultProps = {
    pricePerUser: .5,
    pricePerCall: .01,
    basePrice: 0
  }

  constructor(props) {
    super(props)

    this.state = {
      numUsers: props.numUsers || 0,
      apiCalls: props.apiCalls || 0
    }
  }

  computeTotal() {
    return (
      this.props.basePrice
      + this.state.numUsers * this.props.pricePerUser
      + this.state.apiCalls * this.props.pricePerCall
    )
  }

  render() {
    return (
      <div style={{padding: 10}}>
        <Heading>${this.computeTotal()}</Heading>
        <H3>for {this.state.numUsers} monthly active users</H3>

        <Slider
          style={{marginTop: 20}}
          onChange={x => this.setState({numUsers: x.target.value})}
          className="slider"
          min="0"
          max="10000"
          value={this.state.numUsers}

          id="numUsers"/>
        {/*<H3>{this.state.apiCalls} api calls</H3>*/}
        {/*<Slider*/}
        {/*  onChange={x => this.setState({apiCalls: x.target.value})}*/}
        {/*  min="0"*/}
        {/*  max="100000"*/}
        {/*  value={this.state.apiCalls}*/}
        {/*  className="slider"*/}
        {/*  id="apiCalls"/>*/}
      </div>
    )
  }
}


const PricingPage = () => (
  <Layout>
    <Helmet
      title={'Bite AI - Pricing'}
      meta={[
        {
          name: 'description',
          content: 'Food Recognition API',
        },
        {
          property: 'og:description',
          content: 'Food Recognition API',
        },
        {
          name: 'keywords',
          content:
            'food recognition, api, deep learning, image recognition,' +
            'food recognition api, food search, food image recognition,' +
            ' food knowledge graph',
        },
      ]}
    />
    {/*<Hero/>*/}
    {/*<ImageRecognition />*/}
    {/*<Search />*/}
    {/*<DetailedNutritionInfo />*/}
    {/*<CustomizedToEachUser />*/}
    {/*<Bitesnap />*/}
    {/*<FeaturesList />*/}
    {/*<SDK />*/}


    <Section>
      <Container style={{flexDirection: 'column', alignItems: 'stretch'}}>
        <Col style={{padding: 40}}>
          <Heading>Get Started Today</Heading>
        </Col>
        <Row style={{
          // flex: 1,
          alignItems: 'stretch',
          justifyContent: 'space-between',
          minHeight: 500
        }}>
          <PricingCard
            lift={1}
            title={'Developer'}
            url={'https://developer.bite.ai/signup/?plan=developer'}
            subtitle={'Build a proof of concept'}
            features={[
              '100 users',
              '10,000 requests per month',
              'Attribution Required',
              'Image Recognition',
              'Knowledge Graph'
            ]}
            price={99}
          />

          <PricingCard
            lift={2}
            title={'Startup'}
            url={'https://developer.bite.ai/signup/?plan=startup'}
            subtitle={'Power up your app'}
            features={[
              '1,000 users',
              '100,000 requests per month',
              'Image Recognition',
              'Knowledge Graph'
            ]}
            price={500}
          />

          <PricingCard
            lift={3}
            title={'Enterprise'}
            subtitle={'Scale to millions of users'}
            features={[
              'Image Recognition',
              'Knowledge Graph',
              'Integration Support',
              'Custom Models',
              'Custom Deployment',
              'Volume Discount',
            ]}
            // price={999}
            buttonText={'Contact Us'}
          />
        </Row>
        <Col style={{alignSelf: 'flex-start', margin: '0 30px'}}>
          <H3>Not Ready To Buy?</H3>
          <Paragraph>Contact us to request a 15 day trial.</Paragraph>
        </Col>
      </Container>
    </Section>

    {/*<Section>*/}
    {/*  <Container>*/}
    {/*    <PriceCalculator price_per_user={.5}/>*/}
    {/*  </Container>*/}
    {/*</Section>*/}

    {/*<Section>*/}
    {/*  <Container style={{flexDirection: 'column', alignItems: 'stretch'}}>*/}
    {/*    <Col style={{padding: 40}}>*/}
    {/*      <Heading>Food Logging SDK</Heading>*/}
    {/*    </Col>*/}
    {/*    <Row style={{*/}
    {/*      // flex: 1,*/}
    {/*      alignItems: 'stretch',*/}
    {/*      justifyContent: 'center',*/}
    {/*      minHeight: 500*/}
    {/*    }}>*/}
    {/*     <PricingCard*/}
    {/*        lift={1}*/}
    {/*        title={'Startup'}*/}
    {/*        url={'https://developer.bite.ai/signup/?plan="startup"'}*/}
    {/*        subtitle={'Power up your app'}*/}
    {/*        features={[*/}
    {/*          'Image Recognition',*/}
    {/*          'Knowledge Graph'*/}
    {/*        ]}*/}
    {/*        flex={3}*/}
    {/*        price={99}*/}
    {/*        >*/}
    {/*       /!*<PriceCalculator />*!/*/}
    {/*     </PricingCard>*/}

    {/*      <PricingCard*/}
    {/*        lift={3}*/}
    {/*        title={'Enterprise'}*/}
    {/*        subtitle={'Scale to millions of users'}*/}
    {/*        features={[*/}
    {/*          'Image Recognition',*/}
    {/*          'Knowledge Graph',*/}
    {/*          'Integration Support',*/}
    {/*          'Custom Models',*/}
    {/*          'Dedicated Deployment',*/}
    {/*          'Volume Discount',*/}
    {/*        ]}*/}
    {/*        flex={2}*/}
    {/*        // price={999}*/}
    {/*        buttonText={'Contact Us'}*/}
    {/*      />*/}
    {/*    </Row>*/}
    {/*    <Col style={{alignSelf: 'flex-start', margin: '0 30px'}}>*/}
    {/*      <H3>Not Ready To Buy?</H3>*/}
    {/*      <Paragraph>Contact us to get a free 15 day trial.</Paragraph>*/}
    {/*    </Col>*/}
    {/*  </Container>*/}
    {/*</Section>*/}

    {/*<FAQ />*/}


    {/*<Contact*/}
    {/*  heading={'Get Started'}*/}
    {/*  paragraph={'Contact us to learn more about the SDK and request access.'}*/}
    {/*  product={'Food Logging SDK'}*/}
    {/*/>*/}
  </Layout>
)

export default PricingPage
